import { useState } from "react";
import APIS from "../../contants/EndPoints";
import useGetHook from "../../customHooks/useGetHook";
import loadable from "@loadable/component";
import usePostHook from "../../customHooks/usePostHook";
import useDeleteHook from "../../customHooks/useDeleteHook";
import useFormDataPatchHook from "../../customHooks/useFormDataPatchHook";
import Loadable from "react-loadable";
import DataLoadSpinner from "../../components/Reusable/Spinner/DataLoadSpinner";

const PageComponent: any = Loadable({
  loader: () => import("../../components/WebPage/pages"),
  loading: () => <DataLoadSpinner />,
});

const Pages = () => {
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [openSaveThemePopup, setOpenSaveThemePopup] = useState<boolean>(false);

  //@ts-ignore
  const { data: pages } = useGetHook({
    queryKey: ["pages"],
    url: `${APIS.PAGES}`,
  });
  // eslint-disable-next-line no-console

  const { mutate } = usePostHook({
    queryKey: ["pages"],
    navigateURL: "/pages",
  });

  // CREATE_USER
  const handleAddPage = async (values) => {
    const url = APIS.PAGES;
    const formData = values;
    try {
      mutate({ url, formData });
      setOpenPopup(false);
    } catch (e) {
      console.log(e);
    }
  };

  //Delete page

  const { isLoading: isDeleteLoading, mutateAsync: deleteMutateAsync } =
    useDeleteHook({ queryKey: ["pages"] });

  const handleDelete = async (setOpenPopup: any, pk: string) => {
    const url = `${APIS.PAGES}${pk}/`;
    try {
      const res = await deleteMutateAsync({ url });
      console.log(res, "ii");
      setOpenPopup(false);
    } catch (err) {
      console.log(err);
    }
  };

  //@ts-ignore
  const { data: previewDomainData } = useGetHook({
    queryKey: ["preview_domain"],
    url: `${APIS.PREVIEW_DOMAIN}`,
  });

  const { mutate: mutateSaveTheme } = usePostHook({
    queryKey: ["save-as-theme"],
    navigateURL: "/pages",
  });

  // CREATE_USER
  const handleSaveAsTheme = async (values) => {
    const url = APIS.SAVE_AS_THEME;

    const formData = values;
    try {
      mutateSaveTheme({ url, formData });
      setOpenSaveThemePopup(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <PageComponent
        pages={pages}
        handleAddPage={handleAddPage}
        handleDelete={handleDelete}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        previewDomainData={previewDomainData}
        handleSaveAsTheme={handleSaveAsTheme}
        openSaveThemePopup={openSaveThemePopup}
        setOpenSaveThemePopup={setOpenSaveThemePopup}
      />
    </>
  );
};

export default Pages;
